import type { RouteRecordRaw } from 'vue-router';
import LoadingView from '@/views/misc/LoadingView.vue';
import TimeoutView from '@/views/misc/TimeoutView.vue';
import { type Component, defineAsyncComponent, defineComponent, h } from 'vue';
import Logger from 'js-logger';
import type { UserRole } from '@/types/auth';
import { isDemo } from '@/utils/env-helpers';
import TaskAssessmentsView from '@/views/simulation/competence-assessments/TaskAssessmentsView.vue';

// Participant module components
const StartView = (): Promise<Component> => lazyLoadView(import('@/views/simulation/StartView.vue'));
const ParticipantsView = (): Promise<Component> => lazyLoadView(import('@/views/ParticipantsView.vue'));
const SimulationsView = (): Promise<Component> => lazyLoadView(import('@/views/simulation/SimulationsView.vue'));
const CompetenciesView = (): Promise<Component> => lazyLoadView(import('@/views/competencies/CompetenciesView.vue'));
const ChallengesView = (): Promise<Component> => lazyLoadView(import('@/views/challenges/ChallengesView.vue'));
const ChallengesSolvingView = (): Promise<Component> =>
    lazyLoadView(import('@/views/challenges/ChallengesSolvingView.vue'));
const SimulationView = (): Promise<Component> => lazyLoadView(import('@/views/simulation/SimulationView.vue'));
const CoursesView = (): Promise<Component> => lazyLoadView(import('@/views/user/CoursesView.vue'));
const CourseTaskViewWrapper = (): Promise<Component> => lazyLoadView(import('@/views/user/CourseTaskViewWrapper.vue'));
const DashboardView = (): Promise<Component> => lazyLoadView(import('@/views/user/DashboardView.vue'));
const AllCompletedTasksView = (): Promise<Component> => lazyLoadView(import('@/views/user/AllCompletedTasksView.vue'));
const ReportView = (): Promise<Component> => lazyLoadView(import('@/views/user/ReportView.vue'));
// Demo module components
const AccessLinkRequestView = (): Promise<Component> => lazyLoadView(import('@/views/demo/AccessLinkRequestView.vue'));
const ChooseDemoView = (): Promise<Component> => lazyLoadView(import('@/views/demo/ChooseDemoView.vue'));
const UseCasesView = (): Promise<Component> => lazyLoadView(import('@/views/demo/UseCasesView.vue'));

// Instructor module components
const LoginView = (): Promise<Component> => lazyLoadView(import('@/views/admin/auth/LoginView.vue'));
const ForgotPasswordView = (): Promise<Component> => lazyLoadView(import('@/views/admin/auth/ForgotPasswordView.vue'));
const ResetPasswordView = (): Promise<Component> => lazyLoadView(import('@/views/admin/auth/ResetPasswordView.vue'));
const AdminView = (): Promise<Component> => lazyLoadView(import('@/views/admin/AdminView.vue'));
const SimulationsManagementView = (): Promise<Component> =>
    lazyLoadView(import('@/views/admin/simulations/SimulationsManagementView.vue'));
const CreateSimulationWizardView = (): Promise<Component> =>
    lazyLoadView(import('@/views/admin/simulations/CreateSimulationWizardView.vue'));
const EditSimulationWizardView = (): Promise<Component> =>
    lazyLoadView(import('@/views/admin/simulations/EditSimulationWizardView.vue'));
const ChallengesManagementView = (): Promise<Component> =>
    lazyLoadView(import('@/views/admin/challenges/ChallengesManagementView.vue'));
const AdminAccountSettingsView = (): Promise<Component> =>
    lazyLoadView(import('@/views/admin/settings/AdminAccountSettingsView.vue'));
const AdminAccountSettingsGeneralView = (): Promise<Component> =>
    lazyLoadView(import('@/views/admin/settings/AdminAccountSettingsGeneralView.vue'));
const AdminAccountSettingsSecurityView = (): Promise<Component> =>
    lazyLoadView(import('@/views/admin/settings/AdminAccountSettingsSecurityView.vue'));
const UsersManagementView = (): Promise<Component> =>
    lazyLoadView(import('@/views/admin/settings/UsersManagementView.vue'));
const SetupAccountView = (): Promise<Component> => lazyLoadView(import('@/views/admin/auth/SetupAccountView.vue'));

// Editor module components
const EditorView = (): Promise<Component> => lazyLoadView(import('@/editor/core/views/EditorView.vue'));
const ContentEditorView = (): Promise<Component> => lazyLoadView(import('@/editor/core/views/ContentEditorView.vue'));
const CoursesEditorView = (): Promise<Component> => lazyLoadView(import('@/editor/core/views/CoursesEditorView.vue'));
const CompetenciesManagementView = (): Promise<Component> =>
    lazyLoadView(import('@/editor/core/views/CompetenciesManagementView.vue'));

const MteSimAccessView = (): Promise<Component> => lazyLoadView(import('@/views/MteSimAccessView.vue'));

const NotFoundView = (): Promise<Component> => lazyLoadView(import('@/views/misc/NotFoundView.vue'));

declare module 'vue-router' {
    interface RouteMeta {
        // If true, the user does not need to be authenticated to access the route.
        noAuth?: boolean;
        // Name of the route to redirect to if the user is not authenticated. Defaults to 'login'.
        redirectOnMissingAuth?: string;
        // Roles that are allowed to access the route.
        requiresRole?: UserRole[];
        // If true, will show a team selection dropdown in the sidebar for instructors.
        allowTeamSelection?: boolean;
        // If true, the currentTeamKey in useTeamSelection can return undefined for instructors.
        hasInstructorMode?: boolean;
    }
}

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        meta: { noAuth: true },
        redirect: isDemo ? '/sign-up' : '/start',
    },
    // ============== MTE Route ==============
    {
        path: '/mte/:scenarioId',
        name: 'mte',
        meta: { noAuth: true },
        component: MteSimAccessView,
    },
    // ============== Auth routes ==============
    {
        path: '/login',
        name: 'login',
        meta: { noAuth: true },
        component: LoginView,
    },
    {
        path: '/forgot-password',
        name: 'forgotPassword',
        meta: { noAuth: true },
        component: ForgotPasswordView,
    },
    {
        path: '/setup-account',
        name: 'setupAccount',
        component: SetupAccountView,
    },
    {
        path: '/reset-password',
        name: 'resetPassword',
        component: ResetPasswordView,
    },
    {
        path: '/confirm-email-change',
        redirect: { name: 'accountSettingsGeneral' },
    },
    // ============== Demo routes ==============
    {
        path: '/sign-up',
        meta: { noAuth: true },
        component: AccessLinkRequestView,
    },
    {
        path: '/select-demo',
        component: ChooseDemoView,
    },
    {
        path: '/use-cases',
        name: 'useCases',
        component: UseCasesView,
    },
    // ============== Participant module routes ==============
    {
        path: '/start',
        name: 'start',
        meta: { noAuth: true },
        component: StartView,
    },
    {
        path: '/app',
        component: ParticipantsView,
        children: [
            {
                path: '',
                redirect: '/app/courses',
            },
            {
                path: 'courses/:id*',
                name: 'courses',
                component: CoursesView,
            },
            {
                path: 'task/:id',
                name: 'courseTask',
                component: CourseTaskViewWrapper,
            },
            {
                path: 'simulations',
                name: 'simulations',
                component: SimulationsView,
            },
            {
                path: 'challenges',
                name: 'challenges',
                component: ChallengesView,
            },
            {
                path: 'competencies',
                name: 'competencies',
                component: CompetenciesView,
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                component: DashboardView,
            },
            {
                path: 'all-completed-tasks',
                name: 'allCompletedTasks',
                component: AllCompletedTasksView,
            },
            {
                path: 'report/:id',
                name: 'report',
                component: ReportView,
            },
            {
                path: 'challenges/:week/:index?',
                name: 'challengesSolving',
                component: ChallengesSolvingView,
            },
            {
                path: 'settings/account',
                component: AdminAccountSettingsView,
                children: [
                    {
                        path: '',
                        name: 'accountSettings',
                        redirect: '/app/settings/account/general',
                    },
                    {
                        path: 'general',
                        name: 'accountSettingsGeneral',
                        component: AdminAccountSettingsGeneralView,
                    },
                    {
                        path: 'security',
                        name: 'accountSettingsSecurity',
                        component: AdminAccountSettingsSecurityView,
                    },
                ],
            },
        ],
    },
    {
        path: '/simulations/:simulationID',
        name: 'simulation',
        meta: { redirectOnMissingAuth: 'start' },
        component: SimulationView,
    },
    {
        // The simulation routes are defined in the simulation definition and are added dynamically. This route catches the case where the user tries to access
        // a simulation route before the simulation has been initialized. It redirects to the simulation view, which will initialize the simulation and then redirect to the
        // original route.
        path: '/simulations/:simulationID/:pathMatch(.*)*',
        redirect: (to) => {
            // We actually don't need to pass the simulationID param to the new route, since it already exists in this matched route.
            // But if we don't define the params manually, the 'pathMatch' param is also passed to the new route, which results in a warning.
            return {
                name: 'simulation',
                query: { postInitRedirect: to.fullPath },
                params: { simulationID: to.params.simulationID },
            };
        },
    },
    // ============== Instructor module routes ==============
    {
        path: '/admin',
        meta: { requiresRole: ['instructor', 'admin'] },
        component: AdminView,
        children: [
            {
                path: '',
                redirect: '/admin/simulations',
            },
            {
                path: 'simulations',
                name: 'simulationsManagement',
                component: SimulationsManagementView,
            },
            {
                path: 'simulations/create',
                name: 'simulationCreationWizard',
                component: CreateSimulationWizardView,
            },
            {
                path: 'simulations/:simulationID/edit',
                name: 'simulationEditWizard',
                component: EditSimulationWizardView,
            },
            {
                path: 'challenges',
                name: 'challengesManagement',
                component: ChallengesManagementView,
            },
            {
                path: 'users',
                name: 'usersManagement',
                component: UsersManagementView,
            },
            {
                path: 'assessment',
                name: 'assessment',
                component: TaskAssessmentsView,
            },
            {
                path: 'competencies',
                name: 'competenciesManagement',
                component: CompetenciesManagementView,
            },

            // Editor routes
            {
                path: 'courses/:id*',
                name: 'coursesManagement',
                component: CoursesEditorView,
            },
            {
                path: '/content-editor',
                name: 'contentEditor',
                component: ContentEditorView,
            },
        ],
    },
    // ============== Editor module routes ==============
    {
        path: '/editor',
        meta: { requiresRole: ['admin'] },
        name: 'editor',
        component: EditorView,
    },
    // ============== Misc Routes ==============
    {
        path: '/error',
        name: 'error',
        meta: { noAuth: true },
        component: NotFoundView,
    },
    {
        path: '/:pathMatch(.*)*',
        meta: { noAuth: true },
        redirect: '/error',
    },
];

export default routes;

const logger = Logger.get('RouteLazyLoading');

/*
REMARK: This function is taken from https://github.com/boydaihungst/vue-enterprise-boilerplate/blob/main/src/router/routes.ts

---------------------- Original comments: ----------------------------

Lazy-loads view components, but with better UX. A loading view
will be used if the component takes a while to load, falling
back to a timeout view in case the page fails to load. You can
use this component to lazy-load a route with:

component: () => lazyLoadView(import('@views/my-view'))

NOTE: Components loaded with this strategy DO NOT have access
to in-component guards, such as beforeRouteEnter,
beforeRouteUpdate, and beforeRouteLeave. You must either use
route-level guards instead or lazy-load the component directly:

component: () => import('@views/my-view')
*/
export function lazyLoadView(AsyncView: Promise<Component>): Promise<Component> {
    const AsyncHandler = defineAsyncComponent({
        loader: () => AsyncView,
        // A component to use while the component is loading.
        loadingComponent: LoadingView,
        // Delay before showing the loading component.
        // Default: 200 (milliseconds).
        delay: 400,
        // A fallback component in case the timeout is exceeded
        // when loading the component.
        errorComponent: TimeoutView,
        // Time before giving up trying to load the component.
        // Default: Infinity (milliseconds).
        timeout: 10000,
        /**
         *
         * @param {*} error Error message object
         * @param {*} retry A function that indicating whether the async component should retry when the loader promise rejects
         * @param {*} fail  End of failure
         * @param {*} attempts Maximum allowed retries number
         */
        onError(error, retry, fail, attempts) {
            logger.error(error, attempts);
            if (error.message.match(/fetch/) && attempts <= 3) {
                // retry on fetch errors, 3 max attempts
                retry();
            } else {
                // Note that retry/fail are like resolve/reject of a promise:
                // one of them must be called for the error handling to continue.
                fail();
            }
        },
    });

    return Promise.resolve(
        defineComponent({
            setup(props, { attrs, slots }) {
                return () => h(AsyncHandler, { ...attrs, ...props }, slots);
            },
        }),
    );
}
